import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl,title2 }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img style={{height:'500px'}} src={imgUrl} />
        <div className="proj-txtx">
          <h4>{title}</h4>
        <span>{description}</span>
        </div>
      </div>
      <a style={{textDecoration:'none', color:'purple'}} href={title2}> <h4>{title2}</h4></a>
    </Col>
  )
}
