import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/event1.jpg";
import projImg2 from "../assets/img/event2.jpg";
import projImg3 from "../assets/img/event3.jpg";
import proj1 from "../assets/img/pr1.jpg"
import proj2 from "../assets/img/pr2.jpg"
import proj3 from "../assets/img/pr3.jpg"
import ser1 from '../assets/img/ser1.jpg'
import ser2 from '../assets/img/ser2.jpg'
import ser3 from '../assets/img/ser3.jpg'
import ser4 from '../assets/img/ser4.jpg'
import ser5 from '../assets/img/ser5.jpg'
import ser6 from '../assets/img/ser6.jpg'
import ser7 from '../assets/img/ser7.jpg'
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Graduation of the group I mentored",
      description: "PDP IT Academy",
      imgUrl: projImg1,
      title2:""
    },
    {
      title: "I was an observer at the PDP University entrance exam",
      description: "Logistic exam",
      imgUrl: projImg2,
      title2:""
    },
    {
      title: "In the summer, I organized an IT camp-2022 for children",
      description: "happy holiday",
      imgUrl: projImg3,
      title2:""
    },];

  const projects1 =[
    {
      title: "Website for chat",
      description: "React/Css",
      imgUrl: proj1,
      title2:"https://second-project-ecru.vercel.app/"
    },
    {
      title: "Website for large fabric",
      description: "React/Redux/Css",
      imgUrl: proj2,
      title2:"https://ekvatorial.uz"
    },
    {
      title: "Design for online technoshop",
      description: "Figma",
      imgUrl: proj3,
      title2:"https://www.figma.com/file/KW6LzEmRC9e7VGcUwfipG2/TechnoSale?type=design&node-id=0-1&mode=design&t=YCjpKPXGXqAeiWEK-0"
    }, 
  ];
  const sertificate = [
    {
      title: "Cource Certificate",
      description: "Python",
      imgUrl: ser1,
      title2:""
    },
    {
      title: "Cource Certificate",
      description: "Html",
      imgUrl: ser2,
      title2:""
    },
    {
      title: "Cource Certificate",
      description: "Javascript",
      imgUrl: ser3,
      title2:""
    },
    {
      title: "Congratulations for the best teaching ever",
      description: "high school",
      imgUrl: ser4,
      title2:""
    },
    {
      title: "IELTS Certificate",
      description: "English/b1",
      imgUrl: ser5,
     title2:""
    },
    {
      title: "Certificate of Foreign language proficiency",
      description: "English/b2",
      imgUrl: ser6,
      title2:""
    },
    {
      title: "Frontend developer",
      description: "Certificate",
      imgUrl: ser7,
      title2:""
    }
    
  ]
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Projects</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Events</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Sertificates</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                    <Row> {
                          projects1.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        } </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                     <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                        </Row>
                      </Tab.Pane>
                    <Tab.Pane eventKey="third">
                       <Row>
                          {
                            sertificate.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                          }
                        </Row>
                      </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
